import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { VueHeadMixin, createHead } from '@unhead/vue'
import VueLogger from 'vuejs3-logger'
import { createAuth0 } from '@auth0/auth0-vue'
import { createRouter, createWebHistory } from 'vue-router'
import { VueQueryPlugin } from '@tanstack/vue-query'
import VueClipboard from 'vue3-clipboard'
import '@/assets/css/global.css'

const isProd = process.env.VUE_APP_ORIGIN?.startsWith('https://')

const loggerOpts = {
  isEnabled: true,
  logLevel : isProd ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}

loadFonts()

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: App },
    { path: '/tracker/:tracker', component: App },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: { 
      queries: {
        retry: false,
        gcTime: 10 * 60 * 1000 // 10 mins
      }
    },
  },
}

createApp(App)
  .use(router)
  .use(vuetify)
  .use(createHead())
  .mixin(VueHeadMixin)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(VueLogger, loggerOpts)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(
    createAuth0({
      domain: "auth.itrackbro.com",
      client_id: "OeXxqaf0HuOJkyJhiKGimlqW48Cpb4AS",
      redirect_uri: window.location.origin,
      audience: 'hasura'
    })
  )
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .mount('#app')
