export const userImage = (user) => {
    return user?.picture ?? null
}

export const userIdHeaderName = 'Auth-Access-Id'

const authHeadersFor = (accessToken, userId) => {
    const authHeaders = { 'Authorization': `Bearer ${accessToken}` }
    authHeaders[userIdHeaderName] = userId // Provide this token for relaxed rate limiting for authenticated users
    return authHeaders
}

export const getUserTrackers = async (accessToken, userId) => {
    const result = await fetch(`${process.env.VUE_APP_API}/getTrackers`, {
        method: 'GET',
        headers: authHeadersFor(accessToken, userId)
    })

    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.trackers
    }

    return []
}

export const addUserTracker = async (accessToken, userId, { carrier, tracker, description = null }) => {
    const result = await fetch(`${process.env.VUE_APP_API}/saveTracker`, {
        method: 'POST',
        body: JSON.stringify({
            carrier,
            tracker,
            description
        }),
        headers: authHeadersFor(accessToken, userId)
    })

    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.saved
    }

    return null
}

export const deleteUserTracker = async (accessToken, userId, tracker) => {
    const result = await fetch(`${process.env.VUE_APP_API}/deleteTracker`, {
        method: 'POST',
        body: JSON.stringify({
            tracker
        }),
        headers: authHeadersFor(accessToken, userId)
    })

    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.returning?.[0]
    }

    return null
}

export const updateUserTracker = async (accessToken, userId, { tracker, carrier, description }) => {
    const result = await fetch(`${process.env.VUE_APP_API}/updateTracker`, {
        method: 'POST',
        body: JSON.stringify({
            old_tracker: tracker,
            new_tracker: tracker,
            new_carrier: carrier,
            new_description: description
        }),
        headers: authHeadersFor(accessToken, userId)
    })

    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.returning?.[0]
    }

    return null
}

export default {
    userIdHeaderName,
    userImage,
    getUserTrackers,
    addUserTracker,
    deleteUserTracker,
    updateUserTracker
}