<template>
    <v-container fluid :height="height" :style="{ 'min-height': height + 'px' }">
      <v-row justify="center" v-if="memeCaption">
        <v-col cols="12">
          <div style="text-align: center;">{{ memeCaption }}</div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img class="mx-auto" :src="memePath" :style="{ 'width': '75%', 'max-width': '75%', 'height': (height + 'px'), 'max-height': (height + 'px'), 'object-fit': 'contain'}" />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { inject, ref } from 'vue'

export default {
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        imagePath: {
            type: String,
            required: false,
            default: null
        },
        caption: {
            type: String,
            required: false,
            default: null
        }
    },
    setup(props) {
        // Logger
        const logger = inject('vuejs3-logger')

        const memePath = ref(props.imagePath)
        const memeCaption = ref(props.caption)
        logger.debug(`Meme w/ props: ${JSON.stringify(props)}`)

        return { memePath, memeCaption }
    }

}
</script>

<style>

</style>