<template>
    <v-list-item lines="three"
      @click="selectSavedTracker(item.tracker); dialogModel = false">
      <template v-slot:prepend>
        <div class="d-flex flex-column justify-center align-center fill-height"
          style="min-width: 80px; padding-left: 0px; padding-right: 8px;">
          <div class="text-center text-caption">{{ prettyDateWithFormat(item.created_at, 'MM/DD/YY') }}
          </div>
          <v-icon
              :color="prettyStringForEnum(item?.status?.status) === 'Delivered' ? 'green' : undefined">
              mdi-package-variant-closed</v-icon>
          <div class="text-center text-caption">{{ prettyStringForEnum(item?.status?.status) }}</div>
        </div>
      </template>
      <template v-slot:append>
        <div class="d-flex flex-column justify-center align-center fill-height" style="min-height: 64px;">
          <div class="text-center text-caption"></div>
          <div class="d-flex justify-center align-center">
            <v-btn variant="plain" icon="mdi-pencil" @click.stop.prevent="editSavedTracker(item)">
            </v-btn>
            <v-btn variant="plain" icon="mdi-content-copy" @click.stop.prevent="copyTrackerToClipboard(item)"></v-btn>
          </div>
          <div class="text-center text-caption"></div>
        </div>
      </template>
      <div class="d-flex flex-column justify-start align-left fill-height" style="width: 100%; min-width: 150px;">
        <v-list-item-title class="strinkingFontSize">{{ item.description }}</v-list-item-title>
        <v-list-item-subtitle>{{ `${item.tracker} (${prettyStringForEnum(item.carrier)})`
          }}</v-list-item-subtitle>
        <v-list-item-subtitle class="text-caption" v-if="prettyETAStringFromStats(item?.status)">{{
          prettyETAStringFromStats(item.status, 'MM/DD/YY') }}</v-list-item-subtitle>
        <v-list-item-subtitle
          class="text-caption text-no-wrap"
          style="text-overflow: ellipsis;"
          v-if="prettyStringForEnum(item?.status?.status) !== 'NOT FOUND' && prettyLocationStringFromLocation(item?.location)"
          >{{ prettyLocationStringFromLocation(item.location) }}</v-list-item-subtitle>
      </div>
    </v-list-item>
  </template>
  
  <script>
  import { inject, onMounted, onBeforeUnmount, watch } from 'vue'
  import {
    prettyStringForEnum,
    prettyDateWithFormat,
    prettyETAStringFromStats,
    prettyLocationStringFromLocation
  } from '../utils/trackingUtils.js'
  
  export default {
      name: 'AuthUserSettingsSavedTrackerListItem',
      props: {
        isDarkTheme: {
          type: Boolean,
          required: true
        },
        item: {
          type: Object,
          required: true
        },
        editSavedTracker: {
          type: Function,
          required: true
        },
        copyTrackerToClipboard: {
          type: Function,
          required: true
        },
        selectSavedTracker: {
          type: Function,
          required: true
        },
        reloadTrackerData: {
          type: Function,
          required: true
        }
      },
      setup(props) {
        // Logger
        const logger = inject('vuejs3-logger')
  
        onMounted(() => {
          logger.debug(`Saved Tracker item ${props.item.tracker} mounted...`)
        })
  
        onBeforeUnmount(() => {
          logger.debug(`Saved Tracker item ${props.item.tracker} unmounted...`)
        })

        // Fetch metadata when item changes
        watch(
          [() => props.item.tracker, () => props.item.carrier, () => props.item.description, () => props.item.location],
          ([newTracker, newCarrier, newDescription, newLocation], [oldTracker, oldCarrier, oldDescription]) => {
          if (newTracker !== oldTracker || newCarrier !== oldCarrier || newDescription !== oldDescription || !newLocation) {
            // Request metadata for this item from the parent
            props.reloadTrackerData(newTracker, newCarrier)
          }
        }, { immediate: true })
  
        return {
          prettyStringForEnum,
          prettyDateWithFormat,
          prettyETAStringFromStats,
          prettyLocationStringFromLocation
        }
      }
  }
  </script>
  
  <style scoped>
      .strinkingFontSize {
          font-size: min(3.2vw, 18px);
      }
  </style>