const isValidDegreeMeasure = (deg) => deg >= -180.0 && deg <= 180.0

const inRadians = (deg) => (deg/180.0) * Math.PI

const inDegrees = (rad) => (rad/Math.PI) * 180.0

const inMiles = (kilometers) => kilometers / 1.609344 // From: https://www.convertworld.com/en/length/mile/miles-to-km.html

const meanEarthRadiusKilometers = 6371.009 // From: https://en.wikipedia.org/wiki/Great-circle_distance

// Compute central angle along shared 'great circle' swept by two points on a sphere (globe)
// Uses 'Haversine' function from: https://en.wikipedia.org/wiki/Great-circle_distance
// Assumes inputs are degree measures
export const computeCentralAngle = ({ lat1, long1, lat2, long2 }) => {
    // Validate inputs
    if (!isValidDegreeMeasure(lat1) ||
    !isValidDegreeMeasure(long1) ||
    !isValidDegreeMeasure(lat2) ||
    !isValidDegreeMeasure(long2)) {
        console.log(`computeCentralAngle: Value out of bounds! (${JSON.stringify({ lat1, long1, lat2, long2 }, null, 2)})`)
        return NaN
    }

    // Compute some partial expressions for Haversine function
    const lat1Rad = inRadians(lat1)
    const long1Rad = inRadians(long1)
    const lat2Rad = inRadians(lat2)
    const long2Rad = inRadians(long2)

    const deltaLat = lat1Rad > lat2Rad ? Math.abs(lat1Rad - lat2Rad) : Math.abs(lat2Rad - lat1Rad)
    const deltaLong = long1Rad > long2Rad ? Math.abs(long1Rad - long2Rad) : Math.abs(long2Rad - long1Rad)

    const sinSquaredDeltaLatOverTwo = Math.sin(deltaLat/2) ** 2
    const sinSquaredDeltaLongOverTwo = Math.sin(deltaLong/2) ** 2
    const sinSquaredSumLatsOverTwo = Math.sin((lat1Rad + lat2Rad)/2) ** 2

    const haversineRadicand = sinSquaredDeltaLatOverTwo + sinSquaredDeltaLongOverTwo * (1 - sinSquaredDeltaLatOverTwo - sinSquaredSumLatsOverTwo)

    const centralAngleRads = 2 * Math.asin(haversineRadicand ** 0.5)

    return inDegrees(centralAngleRads)
}

// Use the central angle swept for the two locations and Earth's 'mean earth radius' to compute the 'Great Circle' distance (miles)
export const computeGreatCircleDistance = ({ lat1, long1, lat2, long2 }) => {
    // Get central angle
    const centralAng = computeCentralAngle({ lat1, long1, lat2, long2 })
    if (isNaN(centralAng)) {
        return NaN
    }
    return inMiles((centralAng / 360.0) * 2 * Math.PI * meanEarthRadiusKilometers)
}

export const averageSpeed = (distance, timeMS) => {
    return (distance) / (timeMS / 1000.0 / 60 / 60) // convert millis to hours
}