<template>
    <div class="pickup-color text-center d-flex justify-center align-center">
        <strong
            class="logo-text mr-2"
            :style="{ 'font-size': 'max(min(' + maxFontSize + 'px, 3.5vw), ' + minFontSize + 'px)' }"
        >
        {{ abbreviated ? logoTextAbbreviated : logoText }}
        </strong>
        {{ abbreviated ? logoTextYear : '' }}
    </div>
</template>

<script>
import { ref } from 'vue'
export default {
        name: 'MHPSLogo',
        props: {
            abbreviated: {
                type: Boolean,
                required: false,
                default: false
            },
            maxFontSize: {
                type: Number,
                required: false,
                default: 48
            },
            minFontSize: {
                type: Number,
                required: false,
                default: 16
            }
        },
        components: {
        },
        setup() {
            const logoTextAbbreviated = ref(`MHPS`)
            const logoTextYear = ref(`${new Date().getFullYear()}`)
            const logoText = ref(`Mike's High Performance Software`)

            return {
                logoText,
                logoTextAbbreviated,
                logoTextYear
            }
        }
    }
</script>

<style lang="scss" scoped>

.logo-text {
     font-family: "Sleigher-Laser-Italic";
     cursor: pointer;
  }

</style>